<template>
  <div>
    <div v-if="!forgetPassword" id="login-container" class="wdgt-container" ref="container">
      <div class="mb-3">
        {{ $t('words.newCustomer') }} <span class="st-link" @click="$emit('show-register')">{{ $t('words.startHere') }}</span>
      </div>
      <form ref="login-form" @submit.stop.prevent="loginForm">
        <div class="login-form">
          <div class="form-field">
            <label for="login-email">{{ $t('words.email') }}</label>
            <div>
              <input id="login-email" v-model="loginCredentials.username" autocomplete="email" type="email" :min="3" name="username" :placeholder="$t('words.email')"
                     @invalid="onInvalid" required>
            </div>
          </div>
          <div class="form-field">
            <label for="login-password">{{ $t('words.password') }}</label>
            <div>
              <input id="login-password" v-model="loginCredentials.password" autocomplete="current-password" type="password" :min="5" name="password" :placeholder="$t('words.password')"
                     @invalid="onInvalid" required>
            </div>
          </div>
        </div>
        <div class="checkout-buttons">
          <button type="submit" class="payButton green d-flex">
            <i class="material-icons-round">login</i>
            {{ $t('actions.login') }}
          </button>
        </div>
      </form>
      <div class="mt-3">
        <span class="link" @click="forgetPassword=true">{{ $t('actions.forgottenPassword') }}</span>
      </div>
    </div>
    <div v-if="forgetPassword" id="recovery-container" class="wdgt-container" ref="container">
      <div class="mb-3">
        {{ $t('words.recoveryPassword') }}
      </div>
      <form ref="recovery-form" @submit.stop.prevent="recoveryForm">
        <div class="login-form">
          <div class="form-field">
            <label for="login-email">{{ $t('words.email') }}</label>
            <div>
              <input id="login-email" v-model="contactEmail" autocomplete="email" type="email" :min="3" name="username" :placeholder="$t('words.email')"
                     @invalid="onInvalid" required>
            </div>
          </div>
        </div>
        <div class="checkout-buttons">
          <button type="submit" class="payButton green d-flex">
            {{ $t('actions.send') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import { LoginCredentials } from '@/models/LoginCredentials';
import Swal from "sweetalert2";
import {i18n} from "@/lang";
import {Customer} from "@/models/Customer";
import CustomerRepository from "@/api/CustomerRepository";

export default
{
  props: {
    forgetPassword: { type:Boolean, required: true},
  },
  data() {
    return {
      loginCredentials: new LoginCredentials(),
      newCustomer: new Customer(),
      contactEmail: ''
    }
  },
  computed: {
    ...mapGetters('basket', ['orderDetails']),
    ...mapGetters('session', ['customer']),
  },
  methods: {
    ...mapActions('session', ['login']),
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    },
    onInvalid(ev) {
      ev.target.classList.add('touched')
    },
    async loginForm() {
      this.loginCredentials.license = this.getLicense();
      try {
        await this.login(this.loginCredentials);
        if(this.orderDetails.length > 0) {
          this.$emit('show-basket');
        } else {
          this.$emit('close-modal');
        }
      } catch (err) {
        if (err.response.data.code === 1000002) {
          await Swal.fire({
            title: i18n.t('messages.loginFail'),
            text: i18n.t('errors.1000002'),
            icon: 'error',
            showCancelButton: false
          });
        }else if(err.response.data.code === 1000030){
          await Swal.fire({
            title: i18n.t('errors.1000030'),
            text: i18n.t('messages.validateYourEmail'),
            icon: 'error',
            showCancelButton: false
          });
        }else if(err.response.data.code === 1000011){
          await Swal.fire({
            title: i18n.t('errors.1000011',{email: this.loginCredentials.username}),
            text: i18n.t('messages.validateYourEmail'),
            icon: 'error',
            showCancelButton: false
          });
        }else{
          await Swal.fire({
            title: i18n.t('messages.loginFail'),
            text: i18n.t('messages.loginFailMessage'),
            icon: 'error',
            showCancelButton: false
          });
        }
      }
    },
    async recoveryForm() {
      try {
        this.newCustomer = await CustomerRepository.recover(this.contactEmail);
        await Swal.fire({
          title: i18n.t('messages.sentEmailRecovery'),
          text: i18n.t('messages.sentEmailRecoveryText'),
          icon: 'success',
          showCancelButton: false
        });
        this.forgetPassword = false;
        this.$emit('show-login');
      } catch (err) {
        if (err.response.data.code === 1000032) {
          await Swal.fire({
            title: i18n.t('messages.emailNotExists'),
            text: i18n.t('errors.1000032',{email:this.contactEmail}),
            icon: 'error',
            showCancelButton: false
          });
          this.forgetPassword = false;
          this.$emit('show-register');
        }else{
          await Swal.fire({
            title: i18n.t('messages.sentEmailRecoveryFail'),
            text: i18n.t('messages.sentEmailRecoveryFailText'),
            icon: 'error',
            showCancelButton: false
          });
        }
      }
    }
  }
}
</script>
<style scoped>
button[type="button"],
button[type="submit"],
input[type=checkbox] {
  cursor: pointer;
}
input.touched:valid {
  /*
  border: inherit !important;
  outline: inherit !important;
  */
}

input.touched:invalid {
  border: 1px solid rgb(156, 33, 25) !important;
  outline: 1px solid rgb(156, 33, 25) !important;
  box-shadow: 0 0 0 0 rgba(156, 33, 25, 1);
  transform: scale(1);
  animation: pulse 2s 50ms;
}

/* Pulse effect on error */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(156, 33, 25, 0.8);
  }

  50% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(156, 33, 25, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(156, 33, 25, 0);
  }
}
.form-field {
  margin-bottom: 15px;
  width: 100%;
  padding-right: 15px;
}

.form-field > label {
  display: block;
  margin-bottom: 5px;
}

.form-field.checkbox > label {
  display: inline;
  margin-left: 5px;
}

.checkout-buttons {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .form-field {
    width: 100%;
  }

  .checkout-buttons {
    flex-direction: column;
    justify-items: center;
    margin-top: 10px;
  }

  .checkout-buttons button {
    width: 100%;
    margin: 0 0 15px 0;
  }
}

@media (max-width: 425px) {
  input {
    width: 100%;
    padding: 5px;
  }
}
button.payButton{
  background-color: #c91919;
  color: white;
  font-size: 20px;
  padding: 10px 40px 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input{
  font-size: 18px;
  padding: 10px;
  margin: 0;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #dedede;
  width: 100%;
}
.checkbox input{
  width: auto;
}
.checkbox label {
  line-height: 25px;
}
.wdgt-form .checkbox label:before{
  background-color: #fafafa;
  border: 1px solid #dedede;
  border-radius: 4px;
  top: 0;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
  .checkout-buttons button{
    width: 100% !important;
    max-width: 100% !important;
    align-items: center;
    justify-content: center;
  }
  .payButton{
    margin-top: 1rem !important;
  }
}

</style>
