<template>
  <li v-if="!customer" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-session">
    <a @click.prevent="showLogin()" href="#" class="md-trigger"><i class="material-icons-round" style="vertical-align: -6px;">person_outline</i></a>
  </li>
  <li v-else class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-session">
    <a class="clickable" @click.prevent><i class="material-icons-round" style="vertical-align: -6px;">person_outline</i> <span>{{ customer.contactName }}</span></a>
    <ul class="sub-menu">
      <li class="menu-item menu-item-type-custom menu-item-object-custom"><a class="clickable" onclick="window.dispatchEvent(new CustomEvent('bambuyo:profile'));">{{ $t('messages.myProfile') }}</a></li>
      <li class="menu-item menu-item-type-custom menu-item-object-custom"><a class="clickable" onclick="window.dispatchEvent(new CustomEvent('bambuyo:change-bookings'));">{{ $t('messages.modifyBookings') }}</a></li>
      <li class="menu-item menu-item-type-custom menu-item-object-custom"><a class="clickable" onclick="window.dispatchEvent(new CustomEvent('bambuyo:my-orders'));">{{ $t('messages.myOrders') }}</a></li>
      <li class="menu-item menu-item-type-custom menu-item-object-custom"><a class="clickable" onclick="window.dispatchEvent(new CustomEvent('bambuyo:recovery-pass'));">{{ $t('messages.changePassword') }}</a></li>
      <li class="menu-item menu-item-type-custom menu-item-object-custom"><a class="clickable" @click="logout()">{{ $t('messages.logOut') }}</a></li>
    </ul>
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  components: { },
  props: {
    // style: { type: String, required: false, default: null }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('session', ['customer'])
  },
  methods: {
    ...mapActions('session', ['logout']),
    showLogin() {
      window.dispatchEvent(new CustomEvent('bambuyo:login'));
    },
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    }
  }
}
</script>

<style scoped>
  .clickable {
    cursor: pointer;
  }
  ul.sub-menu{
    background: #ffffff;
  }
</style>
