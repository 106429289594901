<template>
  <div id="_pg-layout">
      <modal v-show="showLogin" hide-footer size="md" id="modal-login" title="Iniciar sesión" @close="showLogin = false">
        <h3 v-if="this.showForgot" slot="header">Recuperar contraseña</h3>
        <h3 v-else slot="header">Iniciar sesión</h3>
        <div slot="body">
          <login @show-register="displayRegister()" @show-login="displayLogin();this.showForgot=false" :forgetPassword="this.showForgot" @show-recovery="this.showForgot=true" @close-modal="showLogin = false" @show-edit="displayRegister()" @show-basket="displayBasket()" />
        </div>
      </modal>
      <modal v-show="showProfile" hide-footer size="lg" id="modal-register" title="Mi perfil" @close="showProfile = false">
        <h3 slot="header">Mi perfil</h3>
        <div slot="body">
          <customer-profile @back="displayProducts()" @updated="showProfile = false" />
        </div>
      </modal>
      <modal v-if="showChangeBookings" hide-footer size="lg" id="modal-change-bookings" title="Modificar reservas" @close="showChangeBookings = false">
        <h3 slot="header">Modificar reservas</h3>
        <div slot="body">
          <change-bookings @back="displayProducts()" :orders="{'startsAt':'asc'}" />
        </div>
      </modal>
      <modal v-if="showOrders" hide-footer size="lg" id="modal-orders" title="Mis pedidos" @close="showOrders = false">
        <h3 slot="header">Mis pedidos</h3>
        <div slot="body">
          <MyOrders @back="displayProducts()" :orders="{'createdAt':'desc'}"/>
        </div>
      </modal>
      <modal v-show="showRegister" hide-footer size="lg" id="modal-register" title="Registro" @close="showRegister = false">
        <h3 slot="header">Registro</h3>
        <div slot="body">
          <register @back="displayProducts()" @show-login="displayLogin()" @show-basket="displayBasket()" />
        </div>
      </modal>
      <modal v-show="urlParamToken && showRecovery" hide-footer size="md" id="modal-recovery" @close="showRecovery = false">
        <h3 slot="header">Recuperar contraseña</h3>
        <div slot="body">
          <recoveryPass v-if="urlParamToken && showRecovery" :token="urlParamToken" @show-recovery="displayBasket()"  @show-login="showRecovery = false;displayLogin()" />
        </div>
      </modal>
      <modal v-show="showBasket" hide-footer size="xl" id="modal-basket" title="Cesta" @close="showBasket = false">
        <h3 slot="header">Cesta</h3>
        <div slot="body">
          <basket @back="showBasket = false" @show-account="displayRegister();" @show-login="displayLogin()"  @show-register="displayRegister()" />
        </div>
      </modal>
      <modal v-show="selectedProduct != null" hide-footer size="lg" id="modal-calendar" @close="selectedProduct = null">
        <h3 slot="header">Calendario</h3>
        <div slot="body">
          <SimpleCalendar v-if="selectedProduct" :product="selectedProduct" @bookingSlotSelected="handleBookingSlotSelected" @cancel="handleCancel" :ref="selectedProduct.id" />
        </div>
      </modal>
      <modal v-show="urlParamOrderId" hide-footer size="lg" id="modal-order" @close="cancelOrder()">
        <h3 slot="header">Pedido</h3>
        <div slot="body">
          <order v-if="urlParamOrderId" :orderId="urlParamOrderId" :paymentError="urlParamPaymentError" @cancel="cancelOrder()" />
        </div>
      </modal>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import basket from '@/components/visitarbodegas/basket';
import login from '@/components/visitarbodegas/login';
import register from '@/components/visitarbodegas/register';
import order from '@/components/order';
import SimpleCalendar from '@/components/visitarbodegas/simpleCalendar';
import MyOrders from '@/components/visitarbodegas/myOrders';
import RecoveryPass from '@/components/visitarbodegas/recoveryPass';
import Modal from '@/components/modal';
import { clone } from '@/utils/objects';
// import OrderRepository from "@/api/OrderRepository";
import CustomerProfile from "@/components/visitarbodegas/customerProfile";
import ChangeBookings from "@/components/changeBookings.vue";

export default {
  components:{
    ChangeBookings,
    CustomerProfile,
    basket,
    login,
    register,
    order,
    SimpleCalendar,
    MyOrders,
    RecoveryPass,
    Modal
  },
  data(){
    return {
      urlParamOrderId:null,
      urlParamPaymentError:null,
      urlParamToken:null,
      selectedProduct:null,
      showBasket: false,
      showLogin: false,
      showRegister: false,
      showProfile: false,
      showOrders: false,
      showChangeBookings: false,
      showRecovery: true,
      showForgot:false
    };
  },
  computed: {
    showProducts () {
      return !this.showBasket && !this.selectedProduct && !this.showLogin && !this.showRegister && !this.showProfile && !this.showOrders && !this.showChangeBookings && !this.showRecovery;
    }
  },
  created() {
    console.log('created visitarBodegas.vue');
    window.addEventListener('bambuyo:login', (event) => {
      console.log(event);
      this.showLogin = !this.showLogin;
    });

    window.addEventListener('bambuyo:basket', (event) => {
      console.log(event);
      this.showBasket = true;
    });

    window.addEventListener('bambuyo:profile', (event) => {
      console.log(event);
      this.showProfile = true;
    });

    window.addEventListener('bambuyo:recovery-pass', (event) => {
      console.log(event);
      this.showRecovery = true;
      this.urlParamToken = localStorage.getItem('token');
    });

    window.addEventListener('bambuyo:my-orders', (event) => {
      console.log(event);
      this.displayOrders();
    });

    window.addEventListener('bambuyo:change-bookings', (event) => {
      console.log(event);
      this.displayChangeBookings();
    });

    window.addEventListener('bambuyo:add-product', (event) => {
      console.log(event);
      const product = event.detail.product;
      if (product.type === 'bookable') {
        this.selectedProduct = product;
      } else {
        this.addProduct(product);
        this.showBasket = true
      }
    });

    let re = new RegExp(/orderId=([\w-]+)/);
    if ( re.test(location.hash) ) {
      this.urlParamOrderId = re.exec(location.hash)[1];
    }

    re = new RegExp(/paymentError=([\w_]+)/);
    if ( re.test(location.hash) ) {
      this.urlParamPaymentError = re.exec(location.hash)[1];
    }

    re = new RegExp(/login=([\w_.-]+)/);
    if ( re.test(location.hash) ) {
      this.logout();
      this.autoLogin(re.exec(location.hash)[1]);
    }

    re = new RegExp(/token=([\w_.-]+)/);
    if ( re.test(location.hash) ) {
      this.urlParamToken = re.exec(location.hash)[1];
    }
  },
  methods: {
    ...mapActions('basket', ['addProduct']),
    ...mapActions('session', ['logout','autoLogin']),
    async cancelOrder() {
      if(this.urlParamPaymentError) {
        // await OrderRepository.delete(this.urlParamOrderId);
      }
      window.location.hash = '';
      this.urlParamOrderId = null;
      this.urlParamPaymentError = null;
      window.dispatchEvent(new CustomEvent('bambuyo:basket'));
    },
    manageAdd(product){
      if(product.type !== 'bookable') {
        this.addProduct(product);
        this.showBasket = true
        return;
      }
      this.selectedProduct = product;
    },
    handleBookingSlotSelected(slot) {
      const product = clone(this.selectedProduct);
      this.reset()
      product.booking = slot;
      this.addProduct(product);
      this.showBasket = true
    },
    handleCancel(){
      this.selectedProduct = null;
    },
    reset() {
      this.selectedProduct = null;
    },
    closeAll() {
      this.showBasket = false;
      this.showLogin = false;
      this.showRegister = false;
      this.showProfile = false;
      this.showOrders = false;
      this.showChangeBookings = false;
      this.showRecovery = false;
    },
    displayLogin() {
      this.closeAll();
      this.showLogin = true;
    },
    displayRegister() {
      this.closeAll();
      this.showRegister = true;
    },
    displayBasket() {
      this.closeAll();
      this.showBasket = true;
    },
    displayProducts() {
      this.closeAll();
      this.showProducts = true;
    },
    displayOrders() {
      this.closeAll();
      this.showOrders = true;
    },
    displayChangeBookings() {
      this.closeAll();
      this.showChangeBookings = true;
    }
  },
}
</script>
<style>
  div#_pg-app{
    padding: 0 !important;
  }
  .link{
    cursor: pointer;
  }
  .st-link{
    cursor: pointer;
    text-decoration: underline;
  }
</style>
